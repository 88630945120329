import React from "react";
import {graphql} from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Img from "gatsby-image";
import {Link} from "gatsby";

const Press = ({data: {datoCmsPresspage: p}}) => {
  return <Layout>
    <SEO title='Press' seo={p.seoMetaTags}/>
    <Img fluid={p.headerimage.fluid}/>
    <div className='font-sans font-light py-2 md:py-8 px-2 lg:px-32 bg-gray-200'>
      <div className="font-title text-3xl uppercase text-left tracking-wide">{p.title}</div>
      <div dangerouslySetInnerHTML={{__html: p.description}}/>
    </div>
    <div className="flex flex-wrap font-sans font-light py-2 md:py-8 px-2 lg:px-32">
      <div className="w-full md:flex-1">
        <Img fluid={p.statisticsimage.fluid} className='md:mr-8'/>
      </div>
      <div className="w-full md:flex-1">
        <div className="font-title text-3xl uppercase text-left tracking-wide">Statistics</div>
        {p.statistics.map(s => (<div key={s.id} className='my-2'>
          <a href={s.link} target='_blank' rel="noopener noreferrer" className='underline'>{s.title}</a>
        </div>))}
      </div>
    </div>
    <div className="flex flex-wrap font-sans font-light py-2 md:py-8 px-2 lg:px-32">
      <div className="w-full md:flex-1">
        <div className="font-title text-3xl uppercase text-left tracking-wide">Media Kit</div>
        <Link to='/mediakit/' className='font-sans font-light tracking-wide text-white my-4 p-2 bg-yellow-700'>Download
          LookStyler Media Kit</Link>
      </div>
      <div className="w-full md:flex-1">
        <Img fluid={p.mediakitimage.fluid}/>
      </div>
    </div>
    <div className="flex flex-col flex-wrap font-sans font-light py-2 md:py-8 px-2 lg:px-32">
      <div className="font-title text-3xl uppercase text-center tracking-wide w-full">Press coverage</div>
      <div className="flex justify-center items-center">
        {p.presscoveragelogos.map(p => p.link ?
          <a key={p.originalId} href={p.link} target='_blank' rel="noopener noreferrer">
            <Img fixed={p.logo.fixed} className='mx-4 my-2'/>
          </a> :
          <Img key={p.originalId} fixed={p.logo.fixed} className='mx-4 my-2'/>)}
      </div>
      <div className="flex flex-wrap w-full justify-center items-center">
        {p.presscoverage.map(p => <a key={p.originalId} className="mx-1" href={p.logo.url} target='_blank'
                                     rel="noopener noreferrer">
          <Img fluid={p.logo.fluid} style={{width: 300}}/>
        </a>)}
      </div>
    </div>
    <div className="flex flex-wrap">
      {p.footerimages.map((i, idx) => <a key={i.originalId} href={i.url} target='_blank' rel="noopener noreferrer"
                                         className={'w-full md:flex-1' + (idx === 0 ? '' : ' md:ml-1')}>
        <Img fluid={i.fluid}/>
      </a>)}
    </div>
  </Layout>;
}

export default Press

export const query = graphql`
    query Press {
        datoCmsPresspage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            headerimage{
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1280", h:"350"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            statisticsimage{
                fluid(maxWidth: 1500) {
                    ...GatsbyDatoCmsFluid
                }
            }
            statistics{
                id
                title
                link
            }
            mediakitimage{
                fluid(maxWidth: 1500) {
                    ...GatsbyDatoCmsFluid
                }
            }
            presscoveragelogos{
                originalId
                logo {
                    originalId
                    fixed(width:120, imgixParams: {auto: "compress,format"}) {
                        ...GatsbyDatoCmsFixed
                    }
                }
                link
            }
            presscoverage{
                originalId
                logo {
                    originalId
                    url
                    fluid(maxWidth:300) {
                        ...GatsbyDatoCmsFluid
                    }
                }
            }
            title
            description
            footerimages{
                originalId
                url
                fluid(maxWidth: 300, imgixParams: {fit: "crop", w:"300", h:"400"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }

`